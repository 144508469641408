@import '/styles/main';

.inno-cardHighlight {
  @include media-breakpoint-up(l) {
    width: 80%;
    float: right;
  }
  display: flex;
  border: 1px solid $grey-border;
}
.inno-cardHighlight__img {
  position: relative;
  left: -5%;
  width: (1/3) * 100%;
  figure {
    float: right;
    margin: 0;
    img {
      max-height: 400px;
      height: 400px;
      max-width: unset;
      width: 500px;
      object-fit: cover;
    }
  }

  @include media-breakpoint-down(l) {
    width: 43%;
    left: 0;
    figure {
      img {
        width: 360px;
      }
    }
  }
  @include media-breakpoint-down(m) {
    width: 30%;
    figure {
      img {
        width: 200px;
      }
    }
  }
}

.inno-cardHighlight__content {
  width: 100%;
}

.inno-cardHighlight__main {
  width: calc(100% / 3);
  padding: 12px;

  :global(.plc-img) {
    height: auto;
    width: 100%;
    margin-bottom: 12px;
  }

  :global(.plc-img) img {
    object-fit: contain;
    height: auto;
    max-height: 150px;
    width: 100%;
  }
}

.inno-cardHighlight__aside {
  width: calc(100% / 3);
  padding: 12px;
  @include media-breakpoint-down(l) {
    width: calc(87% / 3);
  }
}

.inno-cardHighlight__selectedCa {
  margin-bottom: calc(50% + 12px);
  background-color: $yellow-sun-dark;
  position: relative;
  right: -10%;
  clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 20% 100%, 0% 85%);
  p {
    padding: 18px 18px 18px 40px;
    @include media-breakpoint-down(l) {
      padding: 18px 18px 18px 30px;
    }
    font-size: 30px;
    font-weight: bold;
    color: $white;
  }
}

.text-overflow {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
