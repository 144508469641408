@import '/styles/main';

.form-hints {
  @include z-index(children);
  transition: transform $time-very-short ease, opacity $time-very-short ease;
  cursor: pointer;
  height: 0;
  
  &.footer {
    position: absolute;
    left: 0;
    top: 64px;
  }
  
  &.visible {
    margin-bottom: $spacing-xxs;
    transform: translateY($spacing-xxs);
    opacity: 1;
  }

  &.hidden {
    transform: translateY(-20px);
    opacity: 0;
    pointer-events: none;
  }

  p {
    position: relative;
    transition: opacity $time-short ease-in;

    &.visible {
      opacity: 1;
    }

    &.hidden {
      opacity: 0;
      height: 0;
    }
  }
}
