@import 'styles/main';

.content {
  align-content: center;
  background-color: $white;
  border-radius: $spacing-s;
  padding: 18px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(m){
    height: 350px;
  }

  .top {
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $spacing-s;
  }
  .middle {
    height: 65%;
    margin-bottom: $spacing-s;
  }
  .bottom {
    height: 15%;
    display: flex;
    align-items: center;
  }
}

