$breakpoints: (
  xs: 365px,
  s: 576px,
  m: 768px,
  l: 1024px,
  xl: 1072px,
  xxl: 1280px,
);

@mixin media-breakpoint-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media only screen and (max-width: #{map-get($breakpoints,$breakpoint) - 1}) {
      @content;
    }
  } @else {
    @error "Invalid breakpoint provided #{$breakpoint}, available breakpoints #{map_keys($breakpoints)}";
  }
}

@mixin media-breakpoint-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @error "Invalid breakpoint provided #{$breakpoint}, available breakpoints #{map_keys($breakpoints)}";
  }
}

@mixin grid-base() {
  display: flex;
  flex-wrap: wrap;

  & > * {
    display: block;
    min-width: 0;
  }
}

@mixin grid-element($divider, $rowGap, $columnGap) {
  // $margin: $columnGap / 2;
  $margin: calc($columnGap / 2);
  margin: 0 -#{$margin};

  & > * {
    margin: 0 $margin $rowGap $margin;
    width: calc(#{calc(100% / $divider)} - #{$columnGap});
  }
}

@mixin grid-1($rowGap, $columnGap) {
  @include grid-element(1, $rowGap, $columnGap);
}

@mixin grid-2($rowGap, $columnGap) {
  @include grid-element(2, $rowGap, $columnGap);
}

@mixin grid-3($rowGap, $columnGap) {
  @include grid-element(3, $rowGap, $columnGap);
}

@mixin grid-4($rowGap, $columnGap) {
  @include grid-element(4, $rowGap, $columnGap);
}

@mixin grid-m-2-s-1() {
  @include grid-base();
  @include grid-1($spacing-m, $spacing-m);
  @include media-breakpoint-up(m) {
    @include grid-2($spacing-m, $spacing-m);
  }
}

@mixin new-grid-m-2-s-1() {
  @include grid-base();
  @include grid-1($spacing-m /4, $spacing-m);
  @include media-breakpoint-up(m) {
    @include grid-2($spacing-m /4, $spacing-m);
  }
}

@mixin grid-m-3-s-1() {
  @include grid-base();
  @include grid-1($spacing-m, $spacing-m);
  @include media-breakpoint-up(m) {
    @include grid-3($spacing-m, $spacing-m);
  }
}

@mixin grid-m-4-s-1() {
  @include grid-base();
  @include grid-1($spacing-m, $spacing-m);
  @include media-breakpoint-up(m) {
    @include grid-4($spacing-m, $spacing-m);
  }
}

@mixin grid-l-3-m-2-s-1() {
  @include grid-base();
  @include grid-1($spacing-m, $spacing-m);
  @include media-breakpoint-up(m) {
    @include grid-2($spacing-m, $spacing-m);
  }
  @include media-breakpoint-up(l) {
    @include grid-3($spacing-m, $spacing-m);
  }
}

@mixin grid-m-3-s-2() {
  @include grid-base();
  @include grid-2($spacing-basis, $spacing-m);
  @include media-breakpoint-up(m) {
    @include grid-3($spacing-xl, $spacing-basis);
  }
}

// Need 2 different loops to make sure the classes with breakpoints come last
// $propName : the name of the CSS property to create the classes for
// $baseClassNamesValues : a map of the classes name prefix and value
@mixin generateResponsiveClasses($propName, $baseClassNamesValues) {
  @each $baseClassName, $baseClassValue in $baseClassNamesValues {
    .#{$baseClassName} {
      #{$propName}: $baseClassValue;
    }
  }
  @each $breakpoint, $breakpoint-value in $breakpoints {
    @each $baseClassName, $baseClassValue in $baseClassNamesValues {
      .#{$baseClassName}-up-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
          #{$propName}: $baseClassValue;
        }
      }
    }
  }
}

@mixin generateResponsiveTexts($textMap) {
  @each $styleName, $styleProps in $textMap {
    .#{$styleName} {
      @each $propName, $propValue in $styleProps {
        #{$propName}: $propValue;
      }
    }
  }
  @each $breakpoint, $breakpoint-value in $breakpoints {
    @each $styleName, $styleProps in $textMap {
      .#{$styleName}-up-#{$breakpoint} {
        @include media-breakpoint-up($breakpoint) {
          @each $propName, $propValue in $styleProps {
            #{$propName}: $propValue;
          }
        }
      }
    }
  }
}
