@import '/styles/main';

.event {
  .card-image-event {
    background: $color-primary-3;

    .content-event {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 0 $spacing-m;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
    }

    .event-card-image-title {
      text-transform: uppercase;
      margin-bottom: 12px;
      @include z-index(children);
    }

    .event-card-image-text {
      text-transform: lowercase;
      @include z-index(children);
    }

    .event-card-illu {
      position: absolute;
      top: 12%;
      right: 8%;
      height: 23%;
      width: 20%;
    }
  }

  @include media-breakpoint-up(s) {
    .event-card .card-image {
      .event-card-image-title {
        font-size: 14px !important;
      }

      .event-card-image-text {
        font-size: 30px !important;
      }
    }
  }

  @include media-breakpoint-up(m) {
    .event-card .card-image {
      .event-card-image-title {
        font-size: 12px !important;
      }

      .event-card-image-text {
        font-size: 26px !important;
      }
    }
  }

  @include media-breakpoint-up(l) {
    .event-card .card-image {
      .event-card-image-title {
        font-size: 14px !important;
      }

      .event-card-image-text {
        font-size: 32px !important;
      }
    }
  }
}
