@import '/styles/main';
@import '/styles/text';

.generic-ad {
  overflow: hidden;
  min-width: 300px;

  @mixin with-ad-label() {
    &:after {
      content: 'Contenu sponsorisé';
      float: right;
      @extend %tag;
      color: $grey-cloud-dark;
      font-weight: 400;
    }
  }

  &.ad-video div {
    max-width: 100%;
  }
  &.ad-block > a {
    @include with-ad-label();
    opacity: 0;
    animation: fade-in 700ms ease-in 100ms forwards;
    display: inline-block;
    > * {
      display: block;
    }
  }

  &.ad-native-small > a {
    @include with-ad-label();
    display: inline-block;
    width: 100%;
  }

  & iframe + a .content-card {
    margin-top: -18px;
  }
}
