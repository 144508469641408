@import 'styles/main';

.meteo-home-summary {
  $leftOffset: 75px;
  position: relative;
  margin: 30px 0 40px 0;
  $main-top-offset: #{18px + $spacing-s};

  @include media-breakpoint-down(m) {
    margin: 0
  }


  &-wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(3, auto);

    @extend %wrapper-s;
    position: relative;

    @include media-breakpoint-up(m) {
      grid-template-columns: 3fr $leftOffset 4fr;
      grid-template-rows: auto 240px auto;
      // IE11 better to put grid placement here
      & .meteo-home-summary-main {
        grid-column-start: 2;
        grid-column-end: span 3;
        grid-row-start: 1;
        grid-row-end: span 3;
      }
      & .meteo-home-summary-radar {
        grid-column-start: 1;
        grid-column-end: span 2;
        grid-row-start: 2;
        grid-row-end: 2;
      }
      & .meteo-home-summary-buttons {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 3;
        grid-row-end: 3;
        justify-content: flex-end;
        display: flex;
        margin-bottom: 0;
      }
    }
  }

  &-search {
    padding-bottom: $spacing-xs;
    .autocomplete {
      margin: $spacing-m 0;
    }
    @include media-breakpoint-up(m) {
      padding-bottom: 0;
      padding-right: $spacing-m;

      label.plc-input .input-box {
        max-width: 292px;
        min-width: auto;
      }
    }
  }

  &-main {
    background: $grey-cloud-ultralight;
    padding: $spacing-basis 0;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      left: -$spacing-m;
      top: 0;
      height: 100%;
      background-color: $grey-cloud-ultralight;
      right: -100vw;
    }

    @include media-breakpoint-up(m) {
      margin-top: $spacing-basis;
      padding-top: $spacing-xl;
      padding-left: $spacing-xl;

      .meteo-city-autocomplete {
        &-signin {
          margin-bottom: $spacing-xl;
        }
      }
    }
  }

  &-buttons {
    text-align: center;

    > button {
      justify-content: center;
      width: 100%;
      max-width: 400px;
      text-align: left;

      &:not(:last-child) {
        margin-bottom: $spacing-m;
      }
    }

    p {
      text-align: center;
    }

    @include media-breakpoint-up(m) {
      padding-top: $spacing-xs;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
      margin: 0;

      button {
        width: auto;
        max-width: none;
      }

      p {
        text-align: left;
      }
    }
    @include media-breakpoint-up(l) {
      > button:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  &-radar {
    width: 100%;
    height: 100%;
    z-index: 0;
    cursor: pointer;
  }
}
