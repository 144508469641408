/**** Colors ****/
$blue-land: #2d3049;
$green-leaf: #84af4b;
$dark-green: #4e7914;
$light-green: #a0d7a2;
$light-forest-green: #6a8f6c;
$green: #006A4E;
$midnight-green: #004652;
$dark-forest-green: #004634;
$bleu-turquoise: #009597;
$bleu-turquoise-dark: #006364;
$grey-cloud-dark: #85889a;
$grey-cloud-light: #f2f3f7;
$grey: #ecedf0;
$grey-medium: #828282;
$grey-cloud-medium: #d5d6df;
$grey-cloud-medium-2: #f7f8fA;
$grey-cloud-ultralight: #f8f9fa;
$grey-border: #c8cadc;
$grey-disabled: #9ca0bd;
$grey-disabled-2: #c3c3c3;
$grey-hover: #2e3049;
$nude-wheat: #ffa569;
$red-pepper: #ff574b;
$red-pepper-dark: #eb4135;
$yellow-sun: #ffd314;
$yellow-sun-dark: #ffc714;
$color-header-border: #efecec;