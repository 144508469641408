@import '/styles/main';

.service-rating {
  &.small {
    .service-rating-rate-container,
    .service-rating-rate {
      height: 7px;
    }
    .service-rating-rate-container {
      order: -1;
    }
  }

  &-rate-container,
  &-rate {
    height: 12px;
  }

  &-rate-container {
    width: 50px;
    background: $color-neutral-2;
  }
  &-rate {
    width: 0;
    background: $color-primary-2;
    transition: width 300ms ease-in-out;
  }
}
