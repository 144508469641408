@import './variables/text';

// Variants
%tag {
  font-size: 12px;
  line-height: 30px;
  font-family: $font-family-1;
  font-weight: 500;
}
%quote {
  font-size: 24px;
  line-height: 34px;
  font-family: $font-family-1;
  font-weight: 700;
  @include media-breakpoint-up(s) {
    font-size: 32px;
    line-height: 42px;
    font-family: $font-family-1;
    font-weight: 700;
  }
  @include media-breakpoint-up(m) {
    font-size: 24px;
    line-height: 34px;
    font-family: $font-family-1;
    font-weight: 700;
  }
  @include media-breakpoint-up(l) {
    font-size: 32px;
    line-height: 42px;
    font-family: $font-family-1;
    font-weight: 700;
  }
}
