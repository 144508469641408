@import 'styles/main';

.content-card {
  min-width: 0;
  padding: $spacing-s 0;

  div:first-child  {
    flex: 1 1 100%;
  }

  .card-image {
    margin-left: auto;
    padding: 0 $spacing-xs;

    img {
      width: 210px;
      height: 130px;
      object-fit: cover;

      @include media-breakpoint-up(m) {
        width: 220px;
        height: 160px;
      }
    }
  }

  .card-title {
    display: flex;
    align-items: center;
    overflow: hidden;
    line-height: normal;
  }
}
