@import 'styles/main';

.button {
  justify-content: center;
  display: flex;
  width: 100%;
  margin-top: $spacing-s;

  @include media-breakpoint-up(m) {
    width: 350px !important;
  }
}

.span {
  color: #ffffff;
  font-size: medium;
}


