@import 'styles/main';

.img-container {
  display: flex;
  padding-bottom: $spacing-m;
  height: 250px;

  img {
    object-fit: cover;
  }
}