@import 'styles/main';

.animated-button.with-animation {
  transition: background-color $time-short ease-in-out;
  .ripple {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;

    .round {
      position: absolute;
      border-radius: 50%;
      width: 200px;
      height: 200px;
      background-color: rgba(0, 0, 0, 0.1);
      animation: scale-in ease-in $time-short forwards;
    }
  }
}
