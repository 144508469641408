@import 'styles/main';

.content-card {
  min-width: 0;
  padding: $spacing-xxs/2 0;
  @include media-breakpoint-up(m) {
    padding: $spacing-xxs 0;
  }

  div:last-child  {
    flex: 1 1 100%;
  }

  .card-image {
    padding-right: $spacing-s;
    img {
      width: 210px;
      height: 90px;
      border-radius: 12px;
      object-fit: cover;

      @include media-breakpoint-up(m) {
        width: 220px;
        height: 120px;
      }
    }
  }

  .card-title {
    margin-top: $spacing-xxs;
    line-height: normal;
    @include media-breakpoint-up(m) {
      margin-top: $spacing-xs;
    }
  }
}
