@import './variables/colors';
@import './variables/responsive';
@import './variables/text';

// 🖌Colors
$white: white;
$color-primary-1: $blue-land;
$color-primary-2: $green-leaf;
$color-primary-3: $red-pepper;
$color-primary-3-hover: $red-pepper-dark;
$color-secondary-1: $yellow-sun;
$color-secondary-1-hover: $yellow-sun-dark;
$color-secondary-2: $nude-wheat;
$color-neutral-1: $grey-cloud-dark;
$color-neutral-2: $grey-cloud-medium;
$color-neutral-3: $grey-cloud-light;
$color-neutral-4: $grey-cloud-ultralight;

// 🖌Colors UX Refonte
$new-color-primary-1: $green;
$new-color-primary-1-hover: $dark-forest-green;
$new-color-primary-2: $light-green;
$new-color-primary-2-hover: $light-forest-green;
$new-color-primary-3: $bleu-turquoise;
$new-color-primary-3-hover: $bleu-turquoise-dark;
$new-color-neutral-1: $grey-cloud-dark;
$black: black;

$text-flavours: (
  light: $white,
  dark: $color-primary-1,
  black: $black,
  yellow: $color-secondary-1,
  green: $color-primary-2,
  red: $color-primary-3,
  grey: $color-neutral-1,
  dark-green: $dark-green,
  meteo-dark-green: $green,
  midnight-green: $midnight-green,
  ultralight: $color-neutral-4,
);
$text-flavours-hover: (
  light: $white,
  dark: $color-primary-1,
  yellow: $color-secondary-1-hover,
  green: $color-primary-2,
  red: $color-primary-3-hover,
  grey: $color-neutral-1,
);

// 📏 Dimensions
$header-height-s: 80px;
$header-height-l: 96px;
$thematic-header-height: 54px;
$search-form-height-xs: 54px;
$search-form-height-s: 64px;
$search-form-height-l: 114px;
$form-input-max-width: 327px;
$content-container-max-width: 848px;
$advert-arch-height: 100px;
$filters-button-width: 60px;

// 👾 Spacings
$spacings: (
  0: 0px,
  xxs: 4px,
  xs: 8px,
  s: 12px,
  m: 24px,
  basis: 36px,
  l: 48px,
  xl: 72px,
  xxl: 96px,
  auto: auto,
);
$spacing-xxs: map-get($spacings, xxs);
$spacing-xs: map-get($spacings, xs);
$spacing-s: map-get($spacings, s);
$spacing-m: map-get($spacings, m);
$spacing-basis: map-get($spacings, basis);
$spacing-l: map-get($spacings, l);
$spacing-xl: map-get($spacings, xl);
$spacing-xxl: map-get($spacings, xxl);

// ⏲ Timers
$time-very-short: 150ms;
$time-short: 350ms;
$time-long: 800ms;

// 📎 Z-Index
$z-index-elements: children, parent, leaflet, dropdown, manage-pref, advert, menu, search-form, header, overlay, modal;

@mixin z-index($element) {
  @if ($element == modal) {
    z-index: 1301; //From material ui https://material-ui.com/customization/z-index/
  } @else {
    z-index: index($z-index-elements, $element);
  }
}

// 🍱 Wrappers
@mixin wrapper($max-width) {
  width: 100%;
  max-width: #{$max-width + 2 * $spacing-m};
  padding-left: $spacing-m;
  padding-right: $spacing-m;
  margin-left: auto;
  margin-right: auto;
}
%wrapper-l {
  @include wrapper(1440px - 2 * $spacing-m);
}
%wrapper-s {
  @include wrapper(1024px);
}
@mixin wrapper-l {
  margin: 0 auto;
  max-width: map-get($breakpoints, l) + 2 * $spacing-m;
  padding: 0 $spacing-m;
  width: 100%;
}

// 💠 Others
%list-item-in {
  // Do not concatenate into animation property
  animation-name: list-item-in;
  animation-duration: $time-short;
  animation-timing-function: ease-in;
}
$box-shadow: 0 0 100px 0 $grey-cloud-medium;

%plc-underline {
  display: inline;
  border-bottom: 1px solid;
}

// Forms
%disabled {
  &:disabled,
  &[disabled],
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    filter: grayscale(100%);
  }
}

%hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

// image ez marge
.align-left.ez-embed-type-image {
  margin-right: 10px;
}
.align-center.ez-embed-type-image {
  text-align: center;
}
