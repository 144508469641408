@import 'styles/main';

@keyframes moveClouds {
  0% {
    transform: translate(0px, 0);
  }
  50% {
    transform: translate(10px, 0);
  }
  100% {
    transform: translate(0px, 0);
  }
}

.plc-img-loader {
  .wrapper {
    position: relative;
    .illu {
      position: absolute;
      top: calc(50% - 125px / 2);
      left: 0;
      .cloud {
        animation: moveClouds ease-in-out $time-long infinite;
      }
    }
  }
}
