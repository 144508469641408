@import 'styles/main';

.service-card {
  &-plc-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0 $spacing-m;

    .tiny-title {
      text-transform: uppercase;
    }
  }
  &-affiliate header {
    .card-header-bottom {
      bottom: $spacing-s;
      left: $spacing-s;
    }
  }
}
