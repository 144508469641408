@import 'styles/main';

.local-meteo-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: $spacing-s;
  position: relative;

  .local-meteo-weather-icon-wrapper {
    position: absolute;
    right: -$spacing-m;
    bottom: -$spacing-m;
    width: 180px;

    @include media-breakpoint-up(s) {
      width: 300px;
    }
    @include media-breakpoint-up(m) {
      width: 217px;
    }
    @include media-breakpoint-up(l) {
      top: -110px;
      width: 345px;
      bottom: auto;
    }
    $xl-weather-code-icon-right-offset: 136px;
    @media only screen and (min-width: map-get($breakpoints, l) + 2 * ($xl-weather-code-icon-right-offset - $spacing-m)) {
      right: -$xl-weather-code-icon-right-offset;
    }
  }

  .local-meteo-weather-label {
    @include z-index(parent);
  }

  .local-meteo-temperature {
    margin-top: $spacing-m;
    white-space: nowrap;
    @include z-index(children);
  }

  @include media-breakpoint-up(l) {
    flex-direction: row;

    .local-meteo-weather-and-date {
      max-width: 70%;
    }
  }
}
