@import 'styles/main';

.meteo-single-metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  text-align: center;
  padding: 0 $spacing-xxs/2;
  height: 100px;
  flex-basis: 20%;
  max-width: 20%;
  justify-content: center;
}

