@import 'styles/main';

.content-card {
  min-width: 0;

  header {
    position: relative;

    .card-header-top {
      position: absolute;
      top: $spacing-s;
      right: $spacing-s;
      @include z-index(children);
      max-width: calc(100% - #{$spacing-s} * 2);
    }

    .card-image {
      position: relative;
      padding-top: 83.75%;
      width: 100%;
      max-height: unset !important;

      > * {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      &.centered {
        border: 1px solid $grey-border;

        > * {
          width: auto;
          height: auto;
          max-width: 80%;
          max-height: 80%;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
      }
    }

    .card-header-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .card-title {
    position: relative;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;

    @include media-breakpoint-down(m) {
      margin-bottom: 10%;
    }
    
    @media (min-width: 600px) {
      height: 72px;
    }
  }
}
